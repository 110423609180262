import React from 'react';

import './style.css';
import styled from "styled-components"

const VersionContainer = styled.span`
    float: left;
    text-align: left;
    font-size: xx-small;
  `

const SupportInfoContainer = styled.span`
    text-align: end;
    float: right;
    font-size: small;
  `

export default () => (
  <footer className="footer-container">
    <div className="footer-content">
      <SupportInfoContainer>
        <a href="https://netmediasystems.se/mottagarlista" target="_blank">Se vilka som har den gröna knappen</a> | För support kontakta <a href="https://www.medialinq.se/faqs/index.html" target="_blank">MediaLinq</a>
      </SupportInfoContainer>
      <VersionContainer>v1.1.2</VersionContainer>
    </div>
  </footer>
);