import React from 'react'

const NavigationStateContext = React.createContext()
const NavigationDispatchContext = React.createContext()

function navigationReducer(state, action) {
  switch (action.type) {
    case 'donationpage': {
      return {donationpage: action.target}
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
function NavigationProvider({children}) {
  const [state, dispatch] = React.useReducer(navigationReducer, {donationpage: ""})
  return (
    <NavigationStateContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={dispatch}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  )
}
function useNavigationState() {
  const context = React.useContext(NavigationStateContext)
  if (context === undefined) {
    throw new Error('useNavigationState must be used within a NavigationProvider')
  }
  return context
}
function useNavigationDispatch() {
  const context = React.useContext(NavigationDispatchContext)
  if (context === undefined) {
    throw new Error('useNavigationDispatch must be used within a NavigationProvider')
  }
  return context
}
export {NavigationProvider, useNavigationState, useNavigationDispatch}